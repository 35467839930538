import React from "react";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import styled from "styled-components";
import { border, color } from "styled-system";

import { Label, Input, LabelAndInput } from "./components/Inputs";
import { DeriveAbilityModifier } from "./util";
import { classes, abilities } from "./class-priorities.json";

const AbilitiesItem = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  margin: 2ch;
  padding: 1ch;
  border-radius: 10px;
  ${border}
  ${color}
`;

const AbilitiesWrapper = styled.div`
  ${color}
  & fieldset {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: space-around;
    align-items: center;
    border-radius: 10px;
    ${color}
    ${border}
  }
`;

const selectAbilities = (state) => state.abilities;
const selectRolls = (state) => {
  // const { rolls, currentRoll, rollHistory } = state;
  const { rolls } = state;
  return [...rolls.rolls].sort().reverse();
}
const selectClass = (state) => state.charClass;
const selectHuman = (state) => state.charRace.human;
const selectRacialMod = (state) => state.charRace.racialMod;

const mapRollsToAbilities = ({ charClass, rolls }) => {
  const sortedRolls = [...rolls].sort((a, b) => b - a);
  const topThreeRolls = [...sortedRolls].slice(0, 3);
  const restRolls = [...sortedRolls].slice(3);
  const charClassPriorities = {
    [classes[charClass][0]]: topThreeRolls[0],
    [classes[charClass][1]]: topThreeRolls[1],
    [classes[charClass][2]]: topThreeRolls[2],
  };
  const restAbilities = abilities.filter((a) => !Object.keys(charClassPriorities).includes(a)).reduce((acc, val, i) => ({ ...acc, [val]: restRolls[i] }), charClassPriorities);
  
  return restAbilities;
};

const racialModMapping = ({ racialMod, currentAttribute, isHuman }) => {
    if (racialMod === currentAttribute && !isHuman) {
      return 2;
    } else if (racialMod === currentAttribute && isHuman) {
      return 1;
    } else if (isHuman) {
      return 1;
    } else {
      return 0;
    }
  };

const memoizedAbilities = createSelector(selectClass, selectRolls, (charClass, rolls) => mapRollsToAbilities({ charClass, rolls }));

const Abilities = () => {
  const selectedAbilities = useSelector(selectAbilities);
  const racialMod = useSelector(selectRacialMod);
  const isHuman = useSelector(selectHuman);
  const prioritizedAbilities = useSelector(memoizedAbilities);

    return (
    <AbilitiesWrapper border="1px solid" borderColor="tomato">
      <fieldset>
        <legend>Abilities</legend>
        {selectedAbilities.allIds.map((ability) => (
          <AbilitiesItem key={ability} border="1px solid" borderColor="pastelpurple">
            <LabelAndInput color="foreground" bg="background"> 
              <Label htmlFor={`abilities-${ability}`}>{ability}</Label>
              <Input
                name={`abilities-${ability}`}
                type="number"
                placeholder={ability}
                value={prioritizedAbilities[ability] || 0}
                readOnly
                />
            </LabelAndInput> 
            <LabelAndInput color="foreground" bg="background">
              <Label htmlFor="racial-mod">Racial Mod</Label>
              <Input
                name="racial-mod"
                type="number"
                placeholder="Racial Mod"
                value={
                racialModMapping({ isHuman, racialMod, currentAttribute: ability }) ||
                  0
                }
                readOnly
                />
            </LabelAndInput>
            <LabelAndInput color="foreground" bg="background">
              <Label htmlFor="ability-racial-total">Ability+Racial</Label>
              <Input
                name="ability-racial-total"
                type="number"
                placeholder="Ability + Racial"
                value={
                racialModMapping({ isHuman, racialMod, currentAttribute: ability }) +
                  prioritizedAbilities[ability] || 0
                }
                readOnly
                />
            </LabelAndInput>
            <LabelAndInput color="foreground" bg="background">
              <Label htmlFor="ability-modifier">Ability Mod</Label>
              <Input
                name="ability-modifier"
                type="number"
                placeholder="Ability Mod"
                value={!prioritizedAbilities[ability] ? 0 : (DeriveAbilityModifier(prioritizedAbilities[ability]))}
                readOnly
                />
            </LabelAndInput>
          </AbilitiesItem>
        ))}
      </fieldset>
    </AbilitiesWrapper>
  );
};
export default Abilities;
