const initialState = {
  currentRoll: 0,
  rollHistory: null,
  rolls: [0, 0, 0, 0, 0, 0, 0, 0, 0],
};

const rollReducer = (state = initialState, action = { type: "default", rolls: { ...initialState } }) => {
  switch (action.type) {
    default:
      return initialState;

    case 'roll':
      if (state.rollHistory) {
        const rollHistory = [...state.rollHistory, action.rolls];
        return {
          rollHistory,
          currentRoll: (state.currentRoll+1),
          rolls: action.rolls,
        };
      } else {
        return {
          rollHistory: [action.rolls],
          currentRoll: 0,
          rolls: action.rolls,
        };

      }
      // const rollHistory = [...state.rollHistory, action.rolls];
      // return {
      //   rollHistory,
      //   currentRoll: (rollHistory.length && rollHistory.length > 0) ? (rollHistory.length) : 0,
      //   rolls: action.rolls,
      // };
  }
}

export default rollReducer;

