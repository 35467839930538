const classReducer = (state = "bard", action = { type: "default" }) => {
  switch (action.type) {
    default:
      return state;
    
    case 'class':
      return action["char-class"];
  }
}

export default classReducer;

