import { combineReducers } from 'redux'

import abilitiesReducer from './abilitiesReducer'
import rollReducer from './rollReducer';
import classReducer from './classReducer';
import raceReducer from './raceReducer';
import charNameReducer from './charNameReducer';

const rootReducer = combineReducers({
  abilities: abilitiesReducer,
  rolls: rollReducer,
  charClass: classReducer,
  charRace: raceReducer,
  charName: charNameReducer
});

export default rootReducer;
