import Races from '../race-modifiers.json';

const RaceModMapping = Races.races;

const initialState = { race: "dwarf", racialMod: RaceModMapping['dwarf'][0], human: false };

const raceReducer = (state = initialState, action = { type: "default" }) => {
  switch (action.type) {
    default:
      return state;
    
    case 'race': {
      if (action["char-race"] !== "human") {
        return { race: action["char-race"], racialMod: RaceModMapping[action['char-race']][0], human: false };
      } else {
        return { race: action["char-race"], racialMod: RaceModMapping[action['char-race']], human: true };
      }
    }
  }
}

export default raceReducer;

