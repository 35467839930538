import React from 'react';
import { useDispatch } from 'react-redux';
import styled, { keyframes } from "styled-components";

import { getThemeColor } from './theme';
import Placeholder from './Placeholder.png';
import roll from './util/roll';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
    border-radius: 10px;
    padding: 1.5em;
    border-style: solid;
  }

  25% {
    transform: rotate(90deg)
    border-radius: 20px;
    padding: 1.25em;
    border-style: solid dashed;
  }

  50% {
    transform: rotate(180deg)
    border-radius: 10px;
    padding: 1em;
    border-style: dotted;
  }

  75% {
    transform: rotate(270deg)
    border-radius: 5px;
    padding: 1.25em;
    border-style: dotted ridge;
  }

  100% {
    transform: rotate(360deg);
    border-radius: 1px;
    padding: 1.5em;
    border-style: ridge solid;
  }
`;

const Button = styled.button`
  margin: auto;
  display: flex;
  padding: 1em;
  border-radius: 10px;
  transition: background-color 800ms cubic-bezier(0.165, 0.84, 0.44, 1), color 800ms cubic-bezier(0.165, 0.84, 0.44, 1), animation;
  color: ${({ theme }) => getThemeColor(theme)("foreground")};
  background: ${({ theme }) => getThemeColor(theme)("background")};
  border: ${({ theme }) => `1px solid ${getThemeColor(theme)("accent2")}`};
  &:hover {
  animation: 2s ${rotate} linear infinite;
    background-color: ${props => props.theme.colors.tomato};
    color: ${props => props.theme.colors.pastelyellow}
  }
`;

const PortraitWrapper = styled.div`
  display: flex;
  background: ${({ theme }) => getThemeColor(theme)("background")});
  color: ${({ theme }) => getThemeColor(theme)("foreground")});
`;

const PlaceholderImage = styled.img`
  display: flex;
  margin: auto;
`;

const getRolls = () => [
  roll(),
  roll(),
  roll(),
  roll(),
  roll(),
  roll(),
  roll(),
  roll(),
  roll(),
];

function Portrait() {
  const dispatch = useDispatch();
  return (
    <PortraitWrapper>
      <PlaceholderImage alt="Placeholder" src={Placeholder} />
      <Button
        border="1px solid"
        alt="Roll"
        value="Roll"
        onClick={() => dispatch({ type: 'roll', rolls: getRolls() })}
      >
        Roll
      </Button>
    </PortraitWrapper>
  );
}

export default Portrait;

