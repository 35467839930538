const charNameReducer = (state = "Name", action = { type: "default", payload: { name: "" } }) => {
  switch (action.type) {
    default:
      return state;

    case 'char-name': {
      return action.payload.name;
    }
  }
}

export default charNameReducer;

