import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { allClasses } from "./class-priorities.json";
import { allIds as allRaces } from "./race-modifiers.json";
import { LabelAndInput, Label, Input, LabelInputSelect } from "./components/Inputs";
import { getThemeColor } from "./theme";

const DetailsWrapper = styled.div`
  border-radius: 10px;

  & fieldset {
    display: flex;
    border-radius: 10px;
  }
`;

const CharNameLabelAndInput = styled(LabelAndInput)`
  padding: 1ch;
  margin: auto;
  align-content: center;
  border-radius: 10px;
  border: 1px solid skyblue;
  color: ${({ theme }) => getThemeColor(theme)("foreground")};
`;


const ClassList = allClasses;
const RaceList = allRaces;

const charClassState = (state) => state.charClass;
const charRaceState = (state) => state.charRace;
const charNameState = state => state['char-name'];

function CharNameInput({ label, name, value }) {
  const charName = useSelector(charNameState)
  const dispatch = useDispatch();
  return (
    <CharNameLabelAndInput color="foreground" bg="background" border="1px solid" borderColor="skyblue">
      <Label htmlFor={name}>{label}</Label>
      <Input
        name={name}
        type="text"
        autoCorrect="off"
        placeholder={label}
        value={value || charName}
        onChange={(e) => dispatch({ type: 'char-name', payload: { name: e.target.value } })}
      />
    </CharNameLabelAndInput>
  );
}


function Details() {
  const charClass = useSelector(charClassState);
  const charRace = useSelector(charRaceState).race;
  const charRacialMod = useSelector(charRaceState).racialMod;
  return (
    <DetailsWrapper bg="background" color="foreground" borderColor="pastelyellow">
      <fieldset>
        <legend>Details</legend>
        <CharNameInput label="Name" name="char-name" />
          <LabelInputSelect
            label="Race"
            name="char-race"
            currentValue={charRace}
            modifier={charRacialMod}
            optionList={RaceList}
            />
          <LabelInputSelect
            label="Class"
            name="char-class"
            currentValue={charClass}
            optionList={ClassList}
            />
      </fieldset>
    </DetailsWrapper>
  );
}

export default Details;
