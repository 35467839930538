import React from "react";
import styled from "styled-components";
import { getThemeColor } from "./theme";

const RollBarWrapper = styled.ul`
display: flex;
border: ${({ theme }) => `1px solid ${getThemeColor(theme)("foreground")}`};
justify-content: flex-start;
max-width: calc(80%);
margin: auto;
padding: 1.5ch;
  li {
    display: flex;
    list-style-type: none;
    padding: 0 0.75ch;
  }
`;

const RollBar = ({ rolls }) => {
  return (
    <RollBarWrapper>
      {rolls && rolls.rollHistory && rolls.rollHistory.map((roll, index) => {
        return <li key={index}>
          <details>
            <summary>
            {index}
            </summary>
            {roll.map(r => <text> [{r}] </text>)}
          </details>
        </li>
      })}
    </RollBarWrapper>
  );
};

export default RollBar;
