import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { border, color } from 'styled-system'

const Label = styled.label`
  text-align: center;
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.5em;
`;

const Input = styled.input`
  text-transform: capitalize;
  text-align: center;
  line-height: 1.80em;
  margin-bottom: 0.5em;
  border-radius: 10px;
  ${border}
  ${color}
  &[type=number] {
    -moz-appearance: textfield;
    outline: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const LabelAndInput = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  text-transform: capitalize;
  margin: auto;
  flex: 0.3 0 auto;
  border-radius: 10px;
  ${color}
  ${border}
`;

const Select = styled.select`
  display: flex;
  text-align: center;
  text-transform: capitalize;
  margin: 1em 1em 1.5em 1em;
  flex: 1 1 auto;
  border-radius: 10px;
  ${color}
  ${border}
  & option {
  border-radius: 10px;
    ${border}
    ${color}
  }
`;

function LabelInputText({ label, name, value }) {
  const dispatch = useDispatch();
  return (
    <LabelAndInput color="foreground" bg="background" border="1px solid" borderColor="accent0">
      <Label htmlFor={name}>{label}</Label>
      <Input
        name={name}
        type="text"
        autocorrect="off"
        placeholder={label}
        value={value || label}
        onChange={(e) => dispatch({ type: name, [label.toLowerCase()]: e.target.value })}
      />
    </LabelAndInput>
  );
}

const LabelInputSelect = ({ label, name, currentValue, optionList }) => {
  const dispatch = useDispatch();
  const dispatchSelect = (e) =>
    dispatch({ type: label.toLowerCase(), [e.target.name]: e.target.value.toLowerCase() });
  return (
    <LabelAndInput color="foreground" bg="background" border="1px solid" borderColor="skyblue">
      <Label htmlFor={name}>{label}</Label>
      <Select
        borderColor="accent4"
        bg="background"
        color="foreground"
        name={name}
        id={name}
        value={currentValue}
        onChange={dispatchSelect}
      >
        <option key={label} value={label} disabled>
          -- {label} --
        </option>
        {optionList.map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </Select>
    </LabelAndInput>
  );
};
export { LabelInputSelect, LabelInputText, Label, LabelAndInput, Input };
