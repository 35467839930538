const abilitiesReducer = () => ({
abilities: {
charisma: 0,
constitution: 0,
dexterity: 0,
intelligence: 0,
luck: 0,
melee: 0,
ranged: 0,
strength: 0,
wisdom: 0,
},
allIds: ["melee", "ranged", "strength", "dexterity", "constitution", "intelligence", "wisdom", "charisma", "luck"] });


export default abilitiesReducer;



