const theme = {
  colors: {
    slate: "#373b41",
    tomato: "#cc6666",
    fadedgreen: "#b5bd68",
    pastelyellow: "#f0c674",
    skyblue: "#81a2be",
    pastelpurple: "#b294bb",
    pastelgreen: "#8abeb7",
    white: "#c5c8c6",
    midnightblue: "#1d1f21",
    background: "#1d1f21",
    foreground: "#c5c8c6",
  },
};

const getThemeColor = (theme) => ({ color }) => theme.colors[color];

export { theme as default, getThemeColor };

