import React from 'react';
import { useSelector } from "react-redux";
import styled, { ThemeProvider } from 'styled-components'

import Portrait from './Portrait';
import RollBar from './RollBar';
import Details from './Details';
import Abilities from './Abilities';
import theme, { getThemeColor } from './theme';

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  min-height: calc(75vh);
  margin: 5ch 20ch;
  row-gap: 2rem;
  align-self: center;
  align-items: space-evenly;
  justify-content: space-evenly;
  border-radius: 10px;
`;

const selectRollHistory = (state) => state.rolls;

function App() {
  const selectedRolls = useSelector(selectRollHistory);
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <RollBar rolls={selectedRolls} />
        <AppWrapper>
          <Portrait />
          <Details getThemeColor={getThemeColor} />
          <Abilities />
        </AppWrapper>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
