// vim: ft=javascript.jsx:
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createGlobalStyle } from 'styled-components'
import { color } from 'styled-system'

import store from "./store";
import theme from './theme';
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const GlobalStyle = createGlobalStyle`
:root {
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  padding: 0;
}

*,
:after,
:before {
  box-sizing: inherit;
}

body {
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Lato,
  Helvetica, Arial, sans-serif;
  ${color}
}
`;

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle theme={theme} bg="background" color="foreground" />
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
